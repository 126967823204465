exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-en-about-tsx": () => import("./../../../src/pages/en/about.tsx" /* webpackChunkName: "component---src-pages-en-about-tsx" */),
  "component---src-pages-en-company-tsx": () => import("./../../../src/pages/en/company.tsx" /* webpackChunkName: "component---src-pages-en-company-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-news-microcms-news-en-news-en-id-tsx": () => import("./../../../src/pages/en/news/{microcmsNewsEn.newsEnId}.tsx" /* webpackChunkName: "component---src-pages-en-news-microcms-news-en-news-en-id-tsx" */),
  "component---src-pages-en-news-tsx": () => import("./../../../src/pages/en/news.tsx" /* webpackChunkName: "component---src-pages-en-news-tsx" */),
  "component---src-pages-en-service-tsx": () => import("./../../../src/pages/en/service.tsx" /* webpackChunkName: "component---src-pages-en-service-tsx" */),
  "component---src-pages-human-rights-tsx": () => import("./../../../src/pages/human-rights.tsx" /* webpackChunkName: "component---src-pages-human-rights-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-microcms-news-news-id-tsx": () => import("./../../../src/pages/news/{microcmsNews.newsId}.tsx" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../../../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-th-about-tsx": () => import("./../../../src/pages/th/about.tsx" /* webpackChunkName: "component---src-pages-th-about-tsx" */),
  "component---src-pages-th-company-tsx": () => import("./../../../src/pages/th/company.tsx" /* webpackChunkName: "component---src-pages-th-company-tsx" */),
  "component---src-pages-th-index-tsx": () => import("./../../../src/pages/th/index.tsx" /* webpackChunkName: "component---src-pages-th-index-tsx" */),
  "component---src-pages-th-news-microcms-news-th-news-th-id-tsx": () => import("./../../../src/pages/th/news/{microcmsNewsTh.newsThId}.tsx" /* webpackChunkName: "component---src-pages-th-news-microcms-news-th-news-th-id-tsx" */),
  "component---src-pages-th-news-tsx": () => import("./../../../src/pages/th/news.tsx" /* webpackChunkName: "component---src-pages-th-news-tsx" */),
  "component---src-pages-th-service-tsx": () => import("./../../../src/pages/th/service.tsx" /* webpackChunkName: "component---src-pages-th-service-tsx" */)
}

